import "./About.css";
import aboutph from "../assets/aboutph.jpeg";
import LinkedIn_logo from "../assets/LinkedIn_logo.png";
import Gmail_logo from "../assets/Gmail-logo.png";
import insta from "../assets/Instagram_logo.png";
import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.div
      className="About"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* <h2>About Me</h2> */}
      <img src={aboutph} alt="me (Seth Taylor)" />
      <p>
        My name is Seth Taylor and I am a Full Stack JavaScript Developer. I
        enjoy bowling, golfing, mountain biking and playing video games. I was
        first introduced to coding through a couple of my college classes and
        was intrigued by what was possible through coding. I gained my coding
        experience by completing the Full Stack JavaScript Bootcamp offered by
        Grand Circus. I'm looking forward to gaining more skills so that I can
        create good looking websites.
      </p>

      <h2>Contact Me Here</h2>
      <a href="https://www.linkedin.com/in/sethjtaylor/">
        <img className="logos" src={LinkedIn_logo} alt="LinkedIn logo" />
      </a>
      <a href="mailto:sethjtaylor05@gmail.com" title="sethjtaylor05@gmail.com">
        <img className="logos" src={Gmail_logo} alt="Gmail logo" />
      </a>
      <a href="https://www.instagram.com/sethro_01/">
        <img className="logos" src={insta} alt="instagram logo" />
      </a>
    </motion.div>
  );
};

export default About;
