import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";

import NavBar from "./components/NavBar";
import AnimatedRoutes from "./components/AnimatedRoutes";
import useCollapse from "react-collapsed";

function Collapsible() {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <div className="collapsible">
      <div className="header" {...getToggleProps()}>
        {isExpanded ? "Welcome to my Website!" : "Welcome to my Website!"}
      </div>
      <div {...getCollapseProps()}>
        <div className="content">
          <p>
            My name is Seth and this is my portfolio website where I will be
            posting some of my past and present projects, as well as some
            insight as to how they were created and what skills I used to make
            them. Feel free to look at the projects that I’ve done so far!
          </p>
          <br />
          <p>Thanks for checking out my website!</p>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Collapsible />
        <AnimatedRoutes></AnimatedRoutes>
      </Router>
    </div>
  );
}

export default App;
