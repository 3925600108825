import "./Home.css";
import Foodstash from "../assets/foodstash.jpg";
import MemoryGame from "../assets/memory-game.jpg";
import ReactLogo from "../assets/react-logo.svg";
import htmlLogo from "../assets/html-img.png";
import cssLogo from "../assets/css-img.png";
import figmaLogo from "../assets/figma-logo.webp";
import TypescriptLogo from "../assets/Typescript-logo.png";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <motion.div
      className="Home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* <div className="welcome-box">
        <h1>Welcome to my Website!</h1>
        <p>
          My name is Seth and this is my portfolio website where I will be
          posting some of my past and present projects, as well as some insight
          as to how they were created and what skills I used to make them. Feel
          free to look at the projects that I’ve done so far!
        </p>
        <i className="fa-solid fa-angle-down"></i>
      </div> */}

      <ul className="project-container">
        <li className="project-item">
          <a href="https://foodstash-1325f.web.app/">
            <h2>Foodstash</h2>
          </a>
          <a className="img-tag" href="https://foodstash-1325f.web.app/">
            <img
              src={Foodstash}
              alt="Screenshot of what the Foodstash website looks like if you were to visit the website."
            />
          </a>
          <p>
            Foodstash is a recipe search engine that retrieves recipes based on
            the parameters inputted by the user. The available parameters are
            the ingredients that you have on hand, the cooking equipment that
            you own, any intolerances that you may have, and what type of diet
            you're on. All of these settings are saved on the user's Google
            profile when they sign-in using it, That data is then stored in our
            MongoDB database. This was a really interesting project to work on
            and it required a bit of unique solutions to get around the issues
            that occured while creating it, but it was fun to do nonetheless.
          </p>
          <h3>Skills/Technologies Used</h3>
          <ul className="tech-list">
            <li className="tech-item">
              <img src={ReactLogo} alt="React logo" />
            </li>
            <li className="tech-item">
              <img src={htmlLogo} alt="html logo" />
            </li>

            <li className="tech-item css-logo">
              <img src={cssLogo} alt="css logo" />
            </li>
            <li className="tech-item ts-logo">
              <img src={TypescriptLogo} alt="Typescript logo" />
            </li>

            <li className="tech-item">
              <img src={figmaLogo} alt="Figma logo" />
            </li>
          </ul>
        </li>

        <li className="project-item">
          <a href="https://tayloset.github.io/Memory-Game-/">
            <h2>Halloween Memory Game</h2>
          </a>
          <a
            className="img-tag"
            href="https://tayloset.github.io/Memory-Game-/"
          >
            <img
              src={MemoryGame}
              alt="Screenshot of what the Halloween Memory game looks like when you go to it's website."
            />
          </a>
          <p>
            This is one of the first major projects that I worked on during the
            Grand Circus Bootcamp and I had a lot of fun creating this Halloween
            themed matching game. I enjoyed picking out the color scheme and
            trying to get the card flipping animations to work properly. Overall
            this was a fun project that I enjoyed making.
          </p>
          <h3>Skills/Technologies Used</h3>
          <ul className="tech-list">
            <li className="tech-item">
              <img src={htmlLogo} alt="html logo" />
            </li>
            <li className="tech-item css-logo">
              <img src={cssLogo} alt="css logo" />
            </li>
            <li className="tech-item ts-logo">
              <img src={TypescriptLogo} alt="Typescript logo" />
            </li>
            <li className="tech-item">
              <img src={figmaLogo} alt="Figma logo" />
            </li>
          </ul>
        </li>
      </ul>
    </motion.div>
  );
};

export default Home;
